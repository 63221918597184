export const sid = "orgftzaexhegkadwknus";

export const environment = {
  production: true,
  supabaseUrl: 'https://' + sid + '.supabase.co',
  supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9yZ2Z0emFleGhlZ2thZHdrbnVzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTE2NzAwMDMsImV4cCI6MjAwNzI0NjAwM30.laz4vTxVkId7pqRotGW0KiCGZBLLvHXCu4Kri_RaJ-I',
  SettingsExpiretime: 600000 // 10*60*1000 = 10 minutes
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
